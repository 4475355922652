









































































































































































































































































































































































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE } from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import proceduresModule from '@/store/modules/proceduresModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV3'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    Multiselect,
     DateRangePicker,
  },
})
export default class TasqsDesktop extends mixins(DataLoading, TasqMixin) {
  @Prop({ type: Boolean, required: false, default: false }) hideDetails ?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) hideSignals ?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) isOpsPage ?: boolean;



  tasqOptionsOpen = false;

  chartsLoading = false;

  signalChartTime = 14;

  subPredictionList: any = [];

  loadingTasqs = false;

  showComment = false;

  comment = '';

  multiTasqType = '';

  customSignalRange: any = { startDate: null, endDate: null }

  visiblePadWellsFrom = 0

  visiblePadWellsTo = 5

  showActionDialog: boolean = false;

  showDataLoadingFeedback = false

   autoCompleteTags: any = [];

  tag: any =  '';
    
  tags: any = [];

  action: any = {
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    date: '',
    well: '',
    jobType: '',
    frequency: '',
    delayedDays: 0,
  };

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  padWellsSkipToEnd() {
	  this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
	  this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }

  padWellsNext() {
	  if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
		  return;
	  }
	  this.visiblePadWellsFrom += 6;
	  this.visiblePadWellsTo += 6;
  }

  padWellsPrevious() {
	  if (this.visiblePadWellsFrom - 6 < 0) {
		  return;
	  }
	  this.visiblePadWellsFrom -= 6;
	  this.visiblePadWellsTo -= 6;
  }

  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }

  //   getPadWellPaginationElement() {
  // 	  return '<div></div>'
  //   }


  getPredictionList() {
    if(this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList)) {

      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map(data => {
        const object:any = this.activeTasq?.predictionsList[data];
        if(data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if(data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return {name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if(!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }

    } else {
      this.subPredictionList = [];
    }

  }


  getWells() {
    const responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
		  for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  if (r < this.visiblePadWellsFrom) {
				  continue;
			  }
			  if (r > this.visiblePadWellsTo) {
				  continue;
			  }
			  // @ts-ignore
        //   if (this.activeTasq.wells[r].predictionType != "Producing") {

				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
        //   }
		  }
		  return responseData;
	  }
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {

    //   }
  }

    async tagsAdded(newTag){
    this.tags.push(newTag);
  
  //  newTags.push(newTag)
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: this.tags})
  }

  async tagsChanged(newTag){
  //  let newTags = this.tags;
  
  //  newTags.push(newTag)
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: [newTag].concat(this.tags)})
  }

   openAddTag(){
      // @ts-ignore
    document.querySelector(".customUINew input").style.background = "transparent";
  }

  closeAddTag(){
      // @ts-ignore
    document.querySelector(".customUINew input").style.background = "#495364";
  }

   async tagsRemove(removeT){
    const index = this.tags.indexOf(removeT);
      if (index > -1) {
        this.tags.splice(index, 1);
      }
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: this.tags})
  }

   get selectedCustomRange() {
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

   get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      
      'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last Year':  [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
    };
  }

   async customDateUpdated(data) {
    let fromNowBack = 0;
    let dateNow: any = new Date()
    const date1 = data.startDate;
    const date2 = data.endDate;
    if(Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))){
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.changeChartTimes(diffDays);
  }

  get jobTypeOptions() {
    return [
      { key: 'Install', value: 'Install', id: 0 },
      { key: 'Install', value: 'Install Artificial Lift', id: 1 },
      { key: 'Install', value: 'Install Compressor ', id: 2 },
      { key: 'Site Check', value: 'Site Check', id: 3 },
      { key: 'Site Check', value: 'Dump Checks', id: 4 },
      { key: 'Site Check', value: 'Facility walkdown', id: 5 },
      { key: 'Site Check', value: 'Drain pots and drips', id: 6 },
      { key: 'Site Check', value: 'Drain tank bottoms', id: 7 },
      { key: 'Well Issue', value: 'Well Issue', id: 8 },
      { key: 'Label', value: 'Label', id: 9 },
      { key: 'LOTO', value: 'LOTO', id: 10 },
      { key: 'Regulatory', value: 'Regulatory', id: 11 },
      { key: 'Preventive Maintenance', value: 'Preventive Maintenance', id: 12 },
      { key: 'Preventive Maintenance', value: 'Facility Winterization', id: 13 },
      { key: 'Preventive Maintenance', value: 'Plunger Inspection', id: 14 },
      { key: 'Gauge', value: 'Gauge', id: 15 },
      { key: 'Contractor Jobs', value: 'Contractor Jobs', id: 16 },
      { key: 'Contractor Jobs', value: 'Hot Oiling', id: 17 },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }

  get tasqListLevel() {

	  return this.activeTasq?.level;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

   

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    const results: any[] = [];
    for (let x = 0; x < this.signalDescriptions.length; x++) {
      for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
        if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
          results.push(tasqSignalsModule.currentSignals[y]);
        }
      }
    }
    // signalDescriptions
    return results;
  }




  get activeTasq() {

    if (this.kanbanTasq) {
	  if (!tasqsListModule.tasqById(this.kanbanTasq)) {
		  if (workspaceModule.tasqById(this.kanbanTasq)) {
			  return workspaceModule.tasqById(this.kanbanTasq)
		  }
	  }

		if (tasqsListModule.tasqById(this.kanbanTasq)) {
			return tasqsListModule.tasqById(this.kanbanTasq)
		}
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
	  return tasqsListModule.activeTasq;
    }
	if (tasqsListModule.activeTasq) {
		return tasqsListModule.activeTasq
	}
    return assetsModule.activeTasq;
  }




  get reassignUsers() {
     return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  async created() {
    tasqsListModule.setFromNowBack(0);
    
    this.fetchTasqAsync();

  }




  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }

  async fetchTasqAsync() {
    assetsModule.getEnabledWells();
    if (this.kanbanTasq) {
      await this.fetchTasq();
      this.getPredictionList();

      if(this.hideDetails) {
        this.prepareAction('wait');
      }
    }
  }

  resetTasq() {
    tasqFeedbackModule.setSystemOptionsAvailable([]);
    tasqFeedbackModule.setSymptomOptionsAvailable([]);
    tasqFeedbackModule.setCauseOptionsAvailable([]);
    tasqFeedbackModule.setActionOptionsAvailable([]);
  }

  beforeDestroy() {
    this.$emit('load-data')
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
  }



  destroyed(){
    
  }


  async updateTasqPrediction(data) {
    window.history.pushState('tasqs', 'Title', '/tasqs/' + data.predictionId + '?type=id');
    this.multiTasqType = data.name;
    this.showDataLoadingFeedback = true;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();

    await tasqsListModule.getTasq({
      PredictionID: data.predictionId,
    });




    // await workflowModule.getJobResponse(data?.predictionId);
    // tasqFeedbackModule.setupPage()

    await this.fetchTasq(data.predictionId, true);
    this.showDataLoadingFeedback = false;


  }


  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: '',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.action.confirmText = 'Add tasq';
        this.action.person = { value: accountModule.user.email, text: `${accountModule.user.firstName} ${accountModule.user.lastName}` };
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  async onActionDialogClosed(response) {
    let successText = '';

    // this.startDataLoading();
    if (response.isOk) {
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
			console.log("tasqsListModule.activeTasqtasqsListModule.activeTasq: ")
			console.log(tasqsListModule.activeTasq)
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
		if (tasqsListModule.activeTasq?.scheduledJobID != null && tasqsListModule.activeTasq?.scheduledJobID != undefined) {
			await scheduleModule.updateItemStatus({
				// @ts-ignore
				item_id: tasqsListModule.activeTasq?.id,
				item_status: "Waiting On"		  
			})
		}


          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
    }

    if(successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    //  this.sleep(2000).then(async () => {
    //   await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    //   this.clearDetails()
    // });
    }else {
      this.$emit('set-action-perform');
    }
    this.action = {};
    this.showActionDialog = false;
    // this.stopDataLoading();

    if(this.hideDetails) {
      this.clearDetails();
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async changeChartTimes(val) {
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
     if(!this.selectedCustomRange){
      tasqsListModule.setFromNowBack(0);
    }
    // tasqsListModule.signalChartTime = val;
    this.chartsLoading = true;

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    promises.push(tasqProductionDataChartModule.getProductionData(val));
    promises.push(tasqProductionDataChartModule.getWellHistory(val));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));

    Promise.all(promises).then(() => {
      this.chartsLoading = false;
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasq(prediction_id = null, loading_from_sub_predictions = false) {
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();
    tasqsListModule.setFromNowBack(0);
    if (!loading_from_sub_predictions) {
      this.chartsLoading = true;
    }
    this.chartTimes = this.$getConst('CHART_TIMES');
    let isProducing = tasqsListModule.activeTasq ? false : true;
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    if (this.signalChartTime > 45) {
      this.signalChartTime = 14;
    }
    if (this.kanbanTasq) {
      if (prediction_id != null) {
        // @ts-ignore
        if (!loading_from_sub_predictions) {
          // @ts-ignore
          await tasqsListModule.setActiveTasq(prediction_id);
          if (tasqsListModule.activeTasq == null) {
            await tasqsListModule.getTasq({
              PredictionID: prediction_id,
            });
          }
        }

      } else if(isProducing) {
        assetsModule.setActiveProducingTasq(this.kanbanTasq);
      }else {
        if (tasqsListModule.activeTasq == null) {
          await tasqsListModule.getTasq({
            PredictionID: this.kanbanTasq,
          });
        }
      }
      if (!loading_from_sub_predictions) {
        await tasqsListModule.setActiveTasq(this.kanbanTasq);
      }


    }



      let wellTags =  await scheduleModule.getTagsForWell({node_id: this.activeTasq?.wellName})
      this.tags = wellTags.map(t => {
        return JSON.parse(t)
      })


      let availableTags =  await scheduleModule.getTagsForWell({node_id: null})
      this.autoCompleteTags = availableTags.map(t => {
        return JSON.parse(t)
      })
 if(this.activeTasq && this.activeTasq?.overriddenName.toLowerCase() === 'real-time'){
      this.signalChartTime = 5
    }else{
      this.signalChartTime = 14;
    }

    if(!this.hideDetails) {


      if (this.activeTasq != null && (this.currentWellType != 'producing') && !isProducing) {
        tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId);
        workflowModule.getWellHistory(this.activeTasq?.wellName);
        //   setpointV2Module.getSetpointRecV2ForTasq({wellName: this.activeTasq?.wellName})
	   if (this.currentWellType != 'producing') {
		  var activeTasqID = this.activeTasq?.id;
		  if (prediction_id != null) {
			  // @ts-ignore
			  activeTasqID = prediction_id;
		  }
		  workflowModule.getJobResponse(activeTasqID);
		  await workflowModule.getJobResponseV2(activeTasqID);
		  if (workflowModule.v2ResponseData != null) {

			  proceduresModule.getProceduresStepsJson({
				  jsonVersion: workflowModule.v2ResponseData.jsonVersion
			  });
		  } else {
			  proceduresModule.getProceduresStepsJson({});
		  }
	  }
      } else {
        workflowModule.getWellHistory(this.activeTasq?.wellName);
      }

 if(this.activeTasq && this.activeTasq?.overriddenName.toLowerCase() === 'real-time'){
      this.signalChartTime = 5
    }

      if (!loading_from_sub_predictions) {
        const promises: any[] = [];
        promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
        promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
        // promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime));
        promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
        Promise.all(promises).then(() => {
          tasqsListModule.setSignalChartTime(this.signalChartTime);
          if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
            let jobOriginExists = false;
            for (let t = 0; t < this.chartTimes.length; t++) {
              if (this.chartTimes[t].text == 'Job Origin') {
                jobOriginExists = true;
              }
            }
            if (!jobOriginExists) {
              this.chartTimes = [{
                id: 5,
                val: this.activeTasq.dateAgoValue + 30,
                text: 'Job Origin',
                additionalClass: 'chart-time--narrow',
              }, ...this.chartTimes];
            }
          }
          this.chartsLoading = false;
        }, (err) => {
          console.log('Error:');
          console.log(err);
          // error occurred
        });
      }
    }

  }

  clearDetails() {
	  tasqFeedbackModule.resetAllData();
    console.log('close');
    this.$emit('close-dialog');
    // tasqsListModule.setActiveTasq('');
    // tasqsListModule.setKanbanSelectedTasq('');
  }

  @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    console.log(id);
    this.getPredictionList();
    if (id) {
      await this.fetchTasq();
    } else {
      tasqsListModule.setActiveTasq('');
    }
  }

   @Watch('hideDetails')
  async onHideDetailsChange(value) {
    if (value) {
      this.prepareAction('reassign');
    } else {
      // tasqsListModule.setActiveTasq('');
    }
  }
}
